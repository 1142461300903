/* eslint-disable */
import React, { useState, useEffect,useContext }  from 'react';
//import Modal from 'react-awesome-modal';
// import Modal from 'react-modal';
import QRCodeReact from 'qrcode.react'
import {ReactComponent as SuccessImage} from '../../assets/images/success-icon.svg';
// import {ReactComponent as Loader}} from '../../assets/images/loader-icon.svg'
import { SendAccessLink } from '../../api/user';


// import { StoreContext } from '../../context/store/StoreProvider';
// import { StoreSelector } from '../StoreSelector/StoreSelector';
import { appConfig } from '../../config/app-config';
import { makeStyles, Grid, RACButton, Typography, Card, CardContent, RACTextbox, RACRadio, CircularProgress, RACModalCard } from '@rentacenter/racstrap'

// var QRCode = require('qrcode.react');
const useClasses = makeStyles((theme: any) => ({
  w100: {
      width: '100%',
  },
  card: {
      borderRadius: '16px!important',
      boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
      width: '100%',
  },
  my5: {
      marginTop: theme.typography.pxToRem(48),
      marginBottom: theme.typography.pxToRem(48),
  },
  mb5: {
      marginBottom: theme.typography.pxToRem(48),
  },
  pt2: {
      paddingTop: theme.typography.pxToRem(8),
  },
  px2: {
      paddingRight: theme.typography.pxToRem(8),
      paddingLeft: theme.typography.pxToRem(8),
  },
  cardHeight: {
      height: '15rem',
  },
  colMD12: {
      flex: '0 0 auto',
      width: '100%',
      paddingRight: '0.75rem',
      // paddingLeft: '0.75rem',
  },
  floatStart: {
      float: 'left'
  },
  floatEnd: {
      float: 'right'
  },
  displayNone: {
      display: 'none'
  },
  displayBlock: {
      display: 'block'
  },
  mb3: {
      marginBottom: '1rem'
  },
  formLabel: {
      marginBottom: '0.2rem !important',
      color: '#111111',
      fontFamily: 'OpenSans-semibold',
      fontSize: '14px',
  },
  formCheckInline: {
      display: 'inline-block',
      marginRight: '1rem',
  },
  formCheck: {
      minHeight: '1.5rem',
      marginBottom: '0.125rem',
  },
  checkboxLabel: {
      fontSize: '14px',
      display: 'inline-block',
      fontFamily: 'OpenSans-regular',
  },
  buttonsContent: {
      float: 'right',
      marginTop: '2rem'
  },
  mr10: {
      marginRight: '10px'
  },
  alignCenter: {
    textAlign: 'center'
  },
    mt10: {
    marginTop: '30px',
  },
  paddingDone: {
    padding: '12px 25px'
},
accessColor: {
    color: '#727171'
},
}));

function SendDigitalForm() {
  const classes = useClasses();
  const [AuthKey, setAuthKey] = useState('');
  //const [AccessToken, setAccessToken] = useState('');
  // const [QueryAuthKey, setQueryAuthKey] = useState('');
  const [masterLoader, setmasterLoader] = useState(false);
  //const [Storeumber, setStoreNumber] = useState('');
  //const [EncryptedStore, setEncryptedStore] = useState('');
//   const { selectedStore } = useContext(StoreContext);
// const [selectedStore, SetselectedStore] = useState('');

 const StoreSelected = window.sessionStorage.getItem('storeNumber')||'';

  // useEffect(() => {

  //   const params = new URLSearchParams(window.location.search) // id=123
  //   const AuthKey = params.get('AuthKey') || "";
  //   setQueryAuthKey(AuthKey);




  // }, []);

  //useEffect(() => {
  //  var encryptedkey = selectedStore !== undefined ? new Buffer(selectedStore).toString("base64") : '';
  //  setStoreNumber(selectedStore !== undefined ? selectedStore.toString():'');
  //  setEncryptedStore(encryptedkey);

  //}, selectedStore);



  //const [DigitalFormPopup, SendDigitalFormPopup] = useState<boolean>(false);
  const [QRCodePopup, SendQRCodePopup] = useState<boolean>(false);
  const [PhoneNumber, SendPhoneNumber] = useState<string>("");
  const [UnformatePhoneNumber, setUnformatePhoneNumber] = useState<string>("");
  const [Email, setEmail] = useState<string>("");
  const [QRCodeURL, SendQRCodeURL] = useState<string>("");
  const [IsEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [IsSuccessPopUpOpen, setIsSuccessPopUpOpen] = useState<boolean>(false);
  const [IsSendDisabled, setIsSendDisabled] = useState<boolean>(true);
  const [IsPhoneNumberVerified, setIsPhoneNumberVerified] = useState<boolean>(false);
  const [IsLookingUpOpen, setIsLookingUpOpen] = useState<boolean>(false);
  const [IsPhysicalStore, setIsPhysicalStore] = useState<boolean>(true);
  const [IsStaticQR, setIsStaticQR] = useState<boolean>(false);

  // function ShowDigitalPopup() {
  //   SendDigitalFormPopup(true);
  // }

  const setQRUrl = () => {
    setIsStaticQR(false);
    console.log(appConfig.dofUrl);
    let URL = (appConfig.dofUrl + "?AuthKey=" + AuthKey).replace(" ", "");
    SendQRCodeURL(URL);

  }

  const setStaticQR = () => {
    setIsStaticQR(true);
    console.log(appConfig.dofUrl);
    var EnAuthStore = StoreSelected !== undefined ? new Buffer(StoreSelected).toString("base64") : '';
    SendQRCodeURL(appConfig.dofUrl +"?StoreCode=" + EnAuthStore);
  }

  const CreateStaticQRCode = () => {
    setStaticQR();
    //SendDigitalFormPopup(false);
    SendQRCodePopup(true);
  }

  const CreateQRCode = async () => {
    setmasterLoader(true);
    let UserInfo =
    {
      'phoneNumber': UnformatePhoneNumber,
      'emailAddress': Email,
      'storeNumber': StoreSelected !== undefined ? StoreSelected.toString() : ''
    }

    const Requestbody = {
        phoneNumber:
          UserInfo.phoneNumber !== '' &&
          UserInfo.phoneNumber !== undefined &&
          UserInfo.phoneNumber !== null
            ? UserInfo.phoneNumber
            : '',
        emailAddress:
          UserInfo.emailAddress !== '' &&
          UserInfo.emailAddress !== undefined &&
          UserInfo.emailAddress !== null
            ? UserInfo.emailAddress
            : '',
        storeNumber: UserInfo.storeNumber
      };
    //console.log(UserInfo);

    let GetAccessToken: any = await SendAccessLink(Requestbody);
    //console.log(GetAccessToken);
    setmasterLoader(false);
    setIsStaticQR(false);

    setAuthKey(GetAccessToken)
    let URL = (appConfig.dofUrl + "?AuthKey=" + GetAccessToken).replace(" ", "");
    //console.log(URL);
    SendQRCodeURL(URL);
    //SendDigitalFormPopup(false);
    //console.log(IsPhysicalStore);
    if(IsPhysicalStore){
    SendQRCodePopup(true);
    } else { setIsSuccessPopUpOpen(true);}
  }
  const HideDigitalPopup = () => {
    SendPhoneNumber('');
    setUnformatePhoneNumber('');
    setEmail('');
    SendQRCodePopup(false);
    setIsSendDisabled(true);
    setIsPhysicalStore(true);
  }
  const HideSuccessPopUp = () => {
    SendPhoneNumber('');
    setUnformatePhoneNumber('');
    setEmail('');
    setIsSendDisabled(true);
    setIsSuccessPopUpOpen(false);
    SendQRCodePopup(false);
    setIsPhysicalStore(true);
   //SendDigitalFormPopup(false);

  }

  const formatPhoneNumber = (value: any) => {
    // if input value is falsy eg if the user deletes the input, then just return
    if (!value) {
      if (IsEmailVerified) { setIsSendDisabled(false); } else { setIsSendDisabled(true); }
      return value;

    }

    // clean the input for any non-digit values.
    const phoneNumber = value.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
    // we need to return the value with no formatting if its less then four digits
    // this is to avoid weird behavior that occurs if you  format the area code to early


    if (phoneNumberLength >= 10) {
      setIsPhoneNumberVerified(true);
      if (Email !== '' && IsEmailVerified) {
        setIsSendDisabled(false);
      }
      else if (Email === '') {
        setIsSendDisabled(false);
      }
      else {
        setIsSendDisabled(true);
      }
    }

    else if (phoneNumberLength < 10 && value.trim() !== '') {
      setIsSendDisabled(true);
    }
    else
    {
      if (IsEmailVerified) { setIsSendDisabled(false); } else { setIsSendDisabled(true); }
    }
    if (phoneNumberLength < 4) return phoneNumber;

    // if phoneNumberLength is greater than 4 and less the 7 we start to return
    // the formatted number
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }

    // finally, if the phoneNumberLength is greater then seven, we add the last
    // bit of formatting and return it.
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }

  const OpenAccessLink =()=>{
    window.open(
      QRCodeURL , "_blank");
  }

  const EmailValueVerify = (event: any) => {
    setQRUrl();
    const phoneNumber = PhoneNumber.replace(/[^\d]/g, "");

    // phoneNumberLength is used to know when to apply our formatting for the phone number
    const phoneNumberLength = phoneNumber.length;
    var EmailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if( event.target.value.length <= 50){
    if (EmailRegex.test(event.target.value) && event.target.value.trim() !== '') {

      setEmail(event.target.value);
      setIsEmailVerified(true);
      if (PhoneNumber !== '' && IsPhoneNumberVerified && phoneNumberLength >= 10) {
        setIsSendDisabled(false);
      }
      else if (PhoneNumber === '' ) {
        setIsSendDisabled(false);

      } else {
        setIsSendDisabled(true);

      }

    } else {
      setEmail(event.target.value);
      setIsEmailVerified(false);
      if (event.target.value === '' && PhoneNumber !== '' && IsPhoneNumberVerified && phoneNumberLength >=10) { setIsSendDisabled(false); }
      else { setIsSendDisabled(true); }


    }
    }

  }

  const handleInput = (e: any) => {
    const PhoneNumber = formatPhoneNumber(e.target.value);
    SendPhoneNumber(PhoneNumber);
    setUnformatePhoneNumber(PhoneNumber.replace(/[-() ]/g, ''));
    setQRUrl();

  }
  const OpenSucessPopUp = () => {
    SendQRCodePopup(false);
    if (IsStaticQR) { setIsSuccessPopUpOpen(false); } else { setIsSuccessPopUpOpen(true); }


  }

  const SetPhysicalStore=()=>
  {
    if(IsPhysicalStore)
    {
      setIsPhysicalStore(false);
    }else{setIsPhysicalStore(true);}
  }

  const openModalContent = () => {
    return (
        <Grid>
            <Grid className={classes.alignCenter}>
                {/* <img src={QRCodeURL} id="QRCode" alt="QR" /> */}
                <QRCodeReact value={QRCodeURL} />,
            </Grid>
            <Grid className={`${classes.alignCenter} ${classes.mt10}`}>
                <RACButton
                    variant="outlined"
                    color="primary" className={`${classes.mr10} ${classes.accessColor}`} onClick={OpenAccessLink}>Access Link</RACButton>
                <RACButton
                    variant="contained"
                    color="primary" className={classes.paddingDone} onClick={OpenSucessPopUp}>Done</RACButton>
            </Grid>
        </Grid>
    )
}
const openModalSuccessContent = () => {
    return (
        <Grid>
            <Grid className={classes.alignCenter}>
                <SuccessImage />
            </Grid>
            <Grid className={`${classes.alignCenter} ${classes.mt10}`}>
                <Typography>Digital Order Form sent successfully.</Typography>
            </Grid>
            <Grid className={`${classes.alignCenter} ${classes.mt10}`}>
                <RACButton
                    variant="contained"
                    color="primary" onClick={HideSuccessPopUp} >OK</RACButton>
            </Grid>
        </Grid>
    )
}


  return (
    <>
    {masterLoader === true ? (
          <Grid
            style={{
              position: 'fixed',
              background: '#f7f5f5',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              opacity: 0.6,
              zIndex: 1050,
              textAlign: 'center',
              margin: '0px 0px',
            }}
          >
            <Grid
              style={{
                display: 'block',
                position: 'fixed',
                zIndex: 9999999,
                top: '40%',
                right: '50%',
              }}
            >
              <CircularProgress />
            </Grid>
          </Grid>
        ) : null}
      <Grid item lg={7}>
            <Card
                className={`${classes.w100}  ${classes.card}  ${classes.cardHeight}`}
            >
                <CardContent>
                    <Grid className={`${classes.w100} ${classes.floatStart}`}>
                        <Grid className={`${classes.colMD12} ${classes.floatStart}`}>
                            <Typography className={`${classes.formLabel} ${classes.w100}`}>Is customer physically in store?</Typography>
                            <Grid className={`${classes.formCheck} ${classes.formCheckInline}`}>
                                {/* <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" defaultValue="option1" defaultChecked /> */}
                                <RACRadio
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    defaultValue='option1'
                                    value=''
                                    size={'small'}
                                    onClick={SetPhysicalStore}
                                    checked={IsPhysicalStore}
                                />
                                <Typography className={classes.checkboxLabel}>Yes</Typography>
                            </Grid>
                            <Grid className={`${classes.formCheck} ${classes.formCheckInline}`}>
                                {/* <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" defaultValue="option2" /> */}
                                <RACRadio
                                    name="inlineRadioOptions"
                                    id="inlineRadio2"
                                    defaultValue='option2'
                                    value=''
                                    size={'small'}
                                    onClick={SetPhysicalStore}
                                    checked={!IsPhysicalStore}
                                />
                                <Typography className={classes.checkboxLabel}>No</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item md={6} lg={6}>
                                <Typography className={classes.formLabel}>Phone Number</Typography>
                                {/* <input type="text" value='' className="form-control" id="PhoneNumber" /> */}
                                <RACTextbox
                                    value={PhoneNumber}
                                    OnChange={handleInput}
                                ></RACTextbox>
                            </Grid>
                            <Grid item md={6} lg={6}>
                                <Typography className={classes.formLabel}>Email</Typography>
                                {/* <input type="email" value='' className="form-control" id="Email" /> */}
                                <RACTextbox
                                    OnChange={EmailValueVerify} 
                                    value={Email}
                                ></RACTextbox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.buttonsContent}>
                    {StoreSelected === undefined ?
                        <>
                        <RACButton variant="contained" color="primary" onClick={CreateStaticQRCode} className={classes.mr10} >Generate Store QR</RACButton>
                        <RACButton variant="contained" color="primary" disabled={true}>Send</RACButton></>:<>
                        <RACButton variant="contained" color="primary" onClick={CreateStaticQRCode} className={classes.mr10} >Generate Store QR</RACButton>
                        <RACButton variant="contained" color="primary" onClick={CreateQRCode} disabled={IsSendDisabled}>Send</RACButton></>}
                        
                        {/* <button type="button" className="btn btn-primary mx-1" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#QRCode">Send</button> */}
                    </Grid>
                </CardContent>
            </Card>
            {/* {IsLookingUpOpen === true ?
            <Grid container className={classes.alignCenter}>
                <Grid
                    style={{
                        position: 'fixed',
                        background: '#f7f5f5',
                        width: '100%',
                        height: '100%',
                        top: 0,
                        left: 0,
                        opacity: 0.6,
                        zIndex: 1050,
                        textAlign: 'center',
                        margin: '0px 0px',
                    }}
                >
                    <Grid
                        style={{
                            display: 'block',
                            position: 'fixed',
                            zIndex: 9999999,
                            top: '40%',
                            right: '50%',
                        }}
                    >
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Grid> : null} */}

            <RACModalCard borderRadius='15px' closeIcon={true} isOpen={QRCodePopup} maxWidth={"xs"} title={'Customer can now scan the QR code'}  onClose={HideDigitalPopup}  children={openModalContent()}></RACModalCard>
            <RACModalCard borderRadius='15px' closeIcon={true} isOpen={IsSuccessPopUpOpen} maxWidth={"xs"} title={''} onClose={HideSuccessPopUp} children={openModalSuccessContent()}></RACModalCard>
        </Grid>



    </>
  );
}

export default SendDigitalForm;
